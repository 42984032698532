<template>
  <div class="login">
    <div class="login__logo">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="login__form">
      <input v-model="password" type="password" placeholder="PASSWORD" />
    </div>
    <div class="login__button">
      <button class="btn-regular primary" v-if="!isLoading" @click="login">
        Login
      </button>
      <button class="btn-regular primary" v-else disabled>Loading...</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "vue-login",
  components: {},
  props: {},
  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
    };
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    login() {
      this.isLoading = true;
      if (this.password == "" && this.email == "") {
        alert("Email and Password required");
      } else {
        this.$store
          .dispatch("login", {
            // email: this.email,
            password: this.password,
          })
          .then((a) => {
            if (a != false) {
              this.isLoading = false;
              this.$router.push({ name: "form" });
            } else {
              this.isLoading = false;
              alert("Wrong Password");
            }
          });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./index.scss";
</style>